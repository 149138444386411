<template>
  <header>
    <div class="width-header d-block mx-auto py-25px">
      <div class="d-inline-flex header-logo">
        <a :href="`${defaultUrl}`">
          <img :src="require('@/assets/images/logo-essencemediacom.png')" alt="Logo Essencemediacom">
        </a>
      </div>
      <nav class="d-inline-flex float-right header-nav">
        <ul>
          <li class="item-desktop"><a class="item item-border font-14px mr-25px" :href="`${defaultUrl}/newsletter`">assine nossa newsletter</a></li>
          <li class="item-desktop"><a class="item item-normal font-14px" :href="`${defaultUrl}#comp-kcdwpjv7`">o que fazemos</a></li>
          <li class="item-desktop"><a class="item item-normal font-14px" :href="`${defaultUrl}#comp-kf4eaez4`">cases</a></li>
          <li class="item-desktop"><a class="item item-normal font-14px" :href="`${defaultUrl}#comp-kf5b9xz8`">clientes</a></li>
          <li class="item-desktop"><a class="item item-normal font-14px" :href="`${defaultUrl}#comp-kf5b9xz8`">liderança</a></li>
          <li class="item-desktop"><a class="item item-normal font-14px" :href="`${defaultUrl}#comp-kf5bud6p`">reconhecimento</a></li>
          <li class="item-desktop"><a class="item item-normal font-14px" :href="`${defaultUrl}#comp-kf5bngjj`">contato</a></li>
        </ul>
      </nav>
      <div class="menu-bar float-right mr-30px">
        <i @click="showMenu" class="fa fa-3x fa-bars cursor-pointer"></i>
      </div>
      <div class="menu-mobile" @click="eventMenu">
        <ul>
          <li class="item-mobile"><a class="font-14px" :href="`${defaultUrl}/newsletter`">newsletter</a></li>
          <hr class="hr-mobile">
          <li class="item-mobile"><a class="font-14px" :href="`${defaultUrl}#comp-kcdwpjv7`">o que fazemos</a></li>
          <hr class="hr-mobile">
          <li class="item-mobile"><a class="font-14px" :href="`${defaultUrl}#comp-kf4eaez4`">clientes</a></li>
          <hr class="hr-mobile">
          <li class="item-mobile"><a class="font-14px" :href="`${defaultUrl}#comp-kf5b9xz8`">liderança</a></li>
          <hr class="hr-mobile">
          <li class="item-mobile"><a class="font-14px" :href="`${defaultUrl}#comp-kf5bud6p`">reconhecimento</a></li>
          <hr class="hr-mobile">
          <li class="item-mobile"><a class="font-14px" :href="`${defaultUrl}#comp-kf5bngjj`">contato</a></li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data: function(){
    return {
      defaultUrl: 'https://www.essencemediacom.com.br'
    }
  },
  methods: {
    eventMenu (event) {
      const menu = document.getElementsByClassName('menu-mobile')
      if (menu !== null) {
        if(menu[0].contains(event.target)) {
          menu[0].style.display = 'none'
        } else {
          menu[0].style.display = 'none'
        }
      }
    },
    showMenu () {
      const menu = document.getElementsByClassName('menu-mobile')
      if (menu !== null) {
        menu[0].style.display = 'block'
      }
    },
    // onClickApp (event) {
    //   const menu = document.getElementsByClassName('menu-mobile')
    //   if (menu !== null && menu[0].style.display =) {
    //     console.log(menu[0].contains(event.target))
    //     if(menu[0].contains(event.target)) {
    //       //menu[0].style.display = 'block'
    //     } else {
    //       //menu[0].style.display = 'none'
    //     }
    //   }
    // }
  },
  mounted () {
    //window.document.addEventListener('click', this.onClickApp)
  },
  beforeDestroy() {
    //window.document.removeEventListener('click', this.onClickApp)
  }
}
</script>

<style>
.width-header {
  max-width: 980px;
}

.mr-25px {
  margin-right: 25px;
}

.mr-30px {
  margin-right: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.py-25px {
  padding-top: 25px;
  padding-bottom: 25px;
}

.font-14px {
  font-size: 14px;
}

header {
  font: normal normal normal 15px/1.4em wfont_992e82_c3559a56c7bb43eaad115c;
  background: white;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
}

.item-desktop {
  margin-top: 2px
}

.item {
  letter-spacing: 0.8px;
  font-weight: bold;
  color: black;
  display: block;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.item-normal:hover {
  color: #ea73b3;
  text-decoration: none;
}

.item-border {
  margin-top: 4px;
  padding: 10px 20px;
  background: #E6E6E2;
  border-radius: 25px;
}

.item-border:hover {
  text-decoration: none;
  color: white;
  background: black;
  transition: background 100ms linear;
}

.menu-mobile {
  display: none;
}

.menu-bar {
  display: none;
}

@media screen and (max-width: 600px) {
  .header-nav {
    display: none !important;
  }

  li {
    float: none;
  }

  .item-border {
    background: none;
    border-radius: 0px;
  }

  .item {
    text-align: right;
  }

  .header-logo {
    margin-left: 30px;
  }

  .menu-bar {
    display: block;
  }

  .menu-mobile {
    padding-top: 100px;
    width: 65%;
    background: black;
    z-index: 1;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    display: none;
  }

  .menu-mobile ul {
    width: 90%;
    margin: 0 auto;
  }

  .item-mobile {
    text-align: right;
  }

  .item-mobile a {
    text-decoration: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
  }

  .hr-mobile {
    background: rgba(255, 255, 255, 0.7);
    width: 50%;
    margin: 10px 0px 10px auto;
    padding-right: 5px;
  }
}
</style>