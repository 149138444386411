<template>
  <footer>
    <div class="width-footer mx-auto padding-footer">
      <b-row>
        <b-col md="3" cols="6">
          <img :src="require('@/assets/images/logo-essencemediacom.png')" alt="Logo Essencemediacom">
        </b-col>
        <b-col md="3" cols="6">
          <b-row>
            <b-col cols="1">
              <IconPhone /> 
            </b-col>
            <b-col cols="10">
              <span class="d-block font-12px font-weight-500">+55 11 3888-8000</span>
              <span class="d-block mt-5n-px font-12px">
                ( temporariamente indisponível,  
              </span>
              <span class="d-block mt-5n-px font-12px">
                por favor nos <a class="footer-link" :href="`${defaultUrl}#comp-kf5bngjj`">mande um email</a> )
              </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="3" cols="12">
          <div class="div-mediums">
            <div class="image-content">
              <a :href="linkedin" target="_blank">
                <img :src="require('@/assets/images/icon-linkedin.webp')" alt="Ícone Linkedin">
              </a>
              <a :href="instagram" target="_blank">
                <img :src="require('@/assets/images/icon-instagram.webp')" alt="Ícone Instagram">
              </a>
              <a :href="facebook" target="_blank">
                <img :src="require('@/assets/images/icon-facebook.webp')" alt="Ícone Facebook">
              </a>
              <a :href="twitter" target="_blank">
                <img :src="require('@/assets/images/icon-twitter.webp')" alt="Logo Twiiter">
              </a>
            </div>
            <div class="d-block">
              <a class="item item-border-footer mx-auto" :href="`${defaultUrl}/newsletter`">ASSINE NOSSA NEWSLETTER</a>
            </div>
          </div>
        </b-col>
        <b-col md="3" cols="12">
          <div class="div-work">
            <div class="text-align-work font-13px">
              <IconSuitcase class="mr-2"/> 
              <a class="footer-link-work" :href="gupyUrl" target="_blank">Trabalhe Conosco</a>
            </div>
            <hr class="hr-footer">
            <div class="text-align-work font-13px">
              <a class="d-block footer-link-work" :href="`${defaultUrl}/legal`">
                Política de privacidade
              </a>
              <a class="d-block footer-link-work" :href="`${defaultUrl}/legal`">
                Declaração de cookies
              </a>
              <a class="d-block footer-link-work" :href="`${defaultUrl}`">
                Termos e condições
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </footer>
</template>

<script>
import IconPhone from '../IconPhone'
import IconSuitcase from '../IconSuitcase'

export default {
  components: { IconPhone, IconSuitcase },
  data: function(){
    return {
      defaultUrl: 'https://www.essencemediacom.com.br',
      gupyUrl: 'https://blinks.gupy.io/',
      linkedin: 'https://www.linkedin.com/company/essencemediacombr',
      instagram: 'https://www.instagram.com/essencemediacombr/',
      facebook: 'https://www.facebook.com/essencemediacombr/',
      twitter: 'https://twitter.com/emglobal'
    }
  }
}
</script>

<style>
footer {
   color: white;
   min-height: 216px;
   width: 100%;
   background: black;
 }

.width-footer {
  max-width: 980px;
}

 .footer-link {
   color: white;
   text-decoration: underline;
 }
 
 .footer-link:hover {
   color: white;
 }

 .font-12px {
   font-size: 12px;
 }

 .font-13px {
   font-size: 13px;
 }

 .font-10px {
   font-size: 10px;
 }

 .mt-5n-px {
   margin-top: -5px;
 }

.item-border-footer {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  margin-top: 4px;
  padding: 15px 20px;
  background: white;
  border-radius: 25px;
}

.item-border-footer:hover {
  text-decoration: none;
  color: white;
  background: #323232;
  transition: background 300ms linear;
}

.image-content {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px 0;
}

.hr-footer {
  border-top: 1px dashed white;
  width: 25%;
  margin: 5px 0 5px auto;
}

.text-align-work {
  text-align: right;
}

.footer-link-work {
  font: normal normal normal 13px/1.3em avenir-lt-w01_35-light1475496,sans-serif;
  color: #9F9F9B;
  text-decoration: underline;
}

.footer-link-work:hover {
  color: #9F9F9B;
  text-decoration: underline;
}

.padding-footer {
  padding-top: 43px;
}

.font-weight-500 {
  font-weight: 500;
}

@media screen and (max-width: 600px) {
  .width-footer {
    width: 90%;
  }

  .div-mediums {
    margin: 0 auto;
    display: block;
    width: 60%;
  }

  .div-work {
    padding: 30px 0px;
    width: 80%;
    margin: 0 auto;
    display: block;
  }

  .text-align-work {
    text-align: center;
  }

  .hr-footer {
    margin: 5px auto;
  }

  .footer-link-work {
    font-size: 15px;
  }
}
</style>